/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { ReactElement, FC, useEffect, useState } from 'react';
import {
  useGetOrganization,
  useGetOrganizationExternalPlatforms,
  useSyncResourcesOrganizationExternalPlatform,
} from '../../services/organization.service';
import { useParams } from 'react-router-dom';

const AdminOrganization: FC<any> = (): ReactElement => {
  const [organization, setOrganization] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organizationExternalPlatforms, setOrganizationExternalPlatforms] = useState<any>([]);
  const { getOrganization } = useGetOrganization();
  const { getOrganizationExternalPlatforms } = useGetOrganizationExternalPlatforms();
  const { syncResourcesOrganizationExternalPlatform } = useSyncResourcesOrganizationExternalPlatform();
  const { id } = useParams();

  const loadOrganization1 = async (orgId: number) => {
    try {
      const apiResponse = await Promise.all([
        getOrganization(orgId),
        getOrganizationExternalPlatforms(orgId),
      ]);
      setOrganization(apiResponse[0].data);
      setOrganizationExternalPlatforms(apiResponse[1].data);

    } catch (error) {
      alert('Error while loading organization');
    }
  };

  useEffect(() => {
    if (id) {
      loadOrganization1(+id);
    }
  }, []);

  const synchronizeResources = async (externalPlatformId: string) => {
    if (id) { // todo remove checking?
      setIsLoading(true)
      await syncResourcesOrganizationExternalPlatform(id, externalPlatformId);
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div>Description: {organization.description}</div>
      <div>Genesys Organization Id: {organization.genesysOrganizationId}</div>
      <div>Genesys OAuthClient Id: {organization.genesysOAuthClientId}</div>
      <div>Genesys OAuthClient Secret: {organization.genesysOAuthClientSecret}</div>
      <br />
      External Platforms
      <table border={1} width={'500px'}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Source ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {organizationExternalPlatforms.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.externalPlatform.name}</td>
              <td>{item.sourceId}</td>
              <td>{item.isActive ? 'true' : 'false'}</td>
              <td>
                {
                  (isLoading) ?
                    (<div>loading ..</div>) :
                    (<button onClick={() => synchronizeResources(item.externalPlatform.id)}>Trigger Synchronization</button>)
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <a href='/admin/organizations'>back</a>
    </div>
  );
};

export default AdminOrganization;
