/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { ReactElement, FC, useEffect, useState } from 'react';
import {
  useGetAllOrganizations,
  useCreateInitialOrganizationConfig,
} from '../../services/organization.service';

const AdminOrganizations: FC<any> = (): ReactElement => {
  const [organizations, setOrganizations] = useState<any>([]);
  const { getAllOrganizations } = useGetAllOrganizations();
  const { createInitialOrganizationConfig } = useCreateInitialOrganizationConfig();

  const loadOrganizations = async () => {
    const fetchedData = await getAllOrganizations();
    setOrganizations(fetchedData.data);
  };

  // TODO Rename the method - process term for webpage action
  const createInitialOrganizationConfigAndLoadPage = async () => {
    const organizationName = prompt('Organization Name', '') || '';
    const genesysOrganizationId = prompt('Genesys Organization Id', '') || '';
    const environment = prompt('Environment', '') || '';

    try {
      if (!(organizationName && genesysOrganizationId)) {
        throw '';
      }
      // TODO Add try catch
      await createInitialOrganizationConfig({ organizationName, genesysOrganizationId, environment });
      alert('Organization created successfully')
    } catch (error) {
      alert('Error creating organization')
    }
    loadOrganizations();
  };
  useEffect(() => {
    // todo improve this code
    loadOrganizations();
  }, []);

  return (
    <div>
      Organizations
      <button onClick={() => createInitialOrganizationConfigAndLoadPage()}>Create with Initial Configuration</button>
      <table border={1}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.description}</td>
              <td><a href={`/admin/organizations/${item.id}`}>edit</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminOrganizations;
