/* eslint-disable no-empty-pattern */
/* eslint-disable react/react-in-jsx-scope */
import { Route, Routes } from 'react-router-dom';
// Note: Remove this not necessary for now
// import Guide from './pages/guide';
import AdminOrganizations from './pages/admin/organizations';
import AdminOrganization from './pages/admin/organization';
// Note: Remove this not necessary for now
// import Home from './pages/home';
import OrganizationSetup from './pages/organization/organization-setup';
import AdminLogs from './pages/admin/logs';
import Help from './pages/help';
import TermsOfService from './pages/terms-of-service';
import Faq from './pages/faq';
import PrivacyPolicy from './pages/privacy-policy';
import Support from './pages/support';
import Sales from './pages/sales';

function App() {
  // TODO fix routing in step component
  return (
    <Routes>
      {/* // Note: Remove this not necessary for now */}
      {/* <Route path="/" element={<Home />}></Route> */}
      <Route path="/organizations/:id" element={<OrganizationSetup />}></Route>
      <Route path="/admin/logs" element={<AdminLogs />}></Route>
      <Route path="/admin/organizations" element={<AdminOrganizations />}></Route>
      <Route path="/admin/organizations/:id" element={<AdminOrganization />}></Route>
      <Route path="/help" element={<Help />}></Route>
      <Route path="/tos" element={<TermsOfService />}></Route>
      <Route path="/faq" element={<Faq />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route path="/support" element={<Support />}></Route>
      <Route path="/sales" element={<Sales />}></Route>
      {/* // Note: Remove this not necessary for now */}
      {/* <Route path="/guide" element={<Guide />}></Route> */}
    </Routes>
  );
}

export default App;
