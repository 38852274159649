/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { ReactElement, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateInitialOrganizationExternalPlatformConfig, useSaveOrganizationConfig, useGetOrganizationConfigsByGenesysCloudOrgId } from '../../services/organization.service';
import '../../styles/organization/organization-setup.css';  // Import the CSS file

const OrganizationSetup: FC<any> = (): ReactElement => {
  // TODO Fix any type
  const [organization, setOrganization] = useState<any>({
    genesysOAuthClientId: '',
    genesysOAuthClientSecret: '',
    externalPlatforms: [],
  });
  const [ischanged, setIsChanged] = useState<boolean>(false);

  const { getOrganizationConfigsByGenesysCloudOrgId } = useGetOrganizationConfigsByGenesysCloudOrgId();
  const { saveOrganizationConfig } = useSaveOrganizationConfig();
  const { createInitialOrganizationExternalPlatformConfig } = useCreateInitialOrganizationExternalPlatformConfig();
  const { id: genesysCloudOrganizationId } = useParams();

  useEffect(() => {
    loadOrganization(genesysCloudOrganizationId as string); // TODO Remove 'as string' type
  }, []);

  const loadOrganization = async (genesysCloudOrganizationId: string) => {
    try {

      // new
      const apiResponse = await getOrganizationConfigsByGenesysCloudOrgId(genesysCloudOrganizationId);
      const organizationConfigs = apiResponse.data;

      setOrganization({
        organizationId: organizationConfigs.organizationId,
        genesysOrganizationId: organizationConfigs.genesysOrganizationId,
        genesysOAuthClientId: organizationConfigs.genesysOAuthClientIdMasked,
        genesysOAuthClientSecret: organizationConfigs.genesysOAuthClientSecretMasked,
        environment: organizationConfigs.environment,
        // TODO To investigate having warning for a default value
        externalPlatforms: organizationConfigs.externalPlatformsConfigs.map((externalPlatform: any) => {
          // TODO return => ({})
          return {
            id: externalPlatform.id,
            connected: externalPlatform.connected,
            sourceId: externalPlatform.sourceId,
            name: externalPlatform.name,
            displayConfigurations: false,
            configurations: externalPlatform.configurationFields.map((field: string) => ({
              name: field,
              value: '',
            })),
          };
        }),
        wrapupCodeSchema: '',
      });
    } catch (error) {
      alert('Error loading organization');
    }
  };

  const displayExternalPlatformConfigurations = (event: any, externalPlatformName: any, currentStatus: boolean) => {
    event.preventDefault();
    const externalPlatforms = organization.externalPlatforms;
    const foundIndex = externalPlatforms.findIndex((externalPlatform: any) => externalPlatform.name === externalPlatformName);
    const externalPlatformTobeUpdated = externalPlatforms[foundIndex];
    externalPlatforms[foundIndex] = { ...externalPlatformTobeUpdated, displayConfigurations: !currentStatus};
    // TODO replace with find
    const organizationData = { ...organization, externalPlatforms };
    // find external platform
    setOrganization(organizationData);
  };


  const saveOrganization = async (event: any) => {
    const formData: any = new FormData(event.target);
    event.preventDefault();
    const organizationToBeSave: any = {
      externalPlatforms: organization.externalPlatforms.map((externalPlatform: any) => {
        const configuration = externalPlatform.configurations.reduce((acc: any, configuration: any) => {
          acc[configuration.name] = formData.get(configuration.name);
          return acc;
        }, {}); // starts with an initial value of 0
        return {
          id: externalPlatform.id,
          configuration,
          sourceId: externalPlatform.sourceId,
          isActive: true
        };
      }),
    };
    try {
      await saveOrganizationConfig(organization.organizationId, organizationToBeSave);
      alert('Saved successfully')
    } catch (error) {
      alert('Error saving organization config')
    }
    loadOrganization(genesysCloudOrganizationId as string); // TODO Remove 'as string' type
  };
  const handleInputChange = () => {
    setIsChanged(true);
  }

  // TODO Rename the method - process term for webpage action
  const createInitialOrganizationExternalPlatformConfigAndLoadPage = async (event: any) => {
    event.preventDefault();
    const externalPlatformName = prompt('External Platform Name', '') || '';
    try {
      if (!externalPlatformName) {
        throw '';
      }
      await createInitialOrganizationExternalPlatformConfig(organization.organizationId, externalPlatformName);
      alert('Created successfully')
    } catch (error) {
      alert('Error creating external platform')
    }
    loadOrganization(organization.genesysOrganizationId); // TODO Fix this
  };

  return (
    <>
      {/* TODO Move this to different component */}
      <header>
        <h1>Limitless Connect - EX Connector</h1>
      </header>
      <div className='section'>
        <form onSubmit={saveOrganization}>
          <h3>Genesys Cloud OAuth</h3>
          <div className="form-section">
            <div className="form-group">
              <label>GENESYS ACCESS KEY ID </label>
              <input
                type="text"
                name="access-key"
                defaultValue={organization.genesysOAuthClientId}
                onChange={handleInputChange}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>GENESYS ACCESS SECRET </label>
              <input
                type="text"
                name="access-secret"
                defaultValue={organization.genesysOAuthClientSecret}
                onChange={handleInputChange}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>ENVIRONMENT </label>
              <input
                type="text"
                name="environment"
                defaultValue={organization.environment}
                onChange={handleInputChange}
                readOnly
              />
            </div>
          </div>
          {(organization.genesysOAuthClientId && organization.genesysOAuthClientSecret) && <div>
            <h3>External Platforms</h3>
            <button onClick={(event) => createInitialOrganizationExternalPlatformConfigAndLoadPage(event)}>Create Initial Config</button>
            {organization.externalPlatforms?.map((externalPlatform: any, index: any) => (
              <div key={index}>
                <h4> Configuration for {(externalPlatform.name).toLocaleUpperCase()}</h4>
                <div className="form-section">
                  <div className="form-group">
                    {/* TODO Enhance the naming of the fields */}
                    <label>SOURCE ID</label>
                    <input
                      name="source-id"
                      type="text"
                      defaultValue={externalPlatform.sourceId}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  {!externalPlatform.displayConfigurations && (
                    <>
                      <div className="form-group">
                        <label>CONNECTED {externalPlatform.connected ? '✅ (Amazon Connect and Kinesis)' : '❌'}</label>
                      </div>
                      <div className="form-group">
                        <button onClick={(event) => displayExternalPlatformConfigurations(event, externalPlatform.name, externalPlatform.displayConfigurations)}>Setup Configuration</button>
                      </div>
                    </>
                  )}
                  {externalPlatform.displayConfigurations && (
                    <>
                      {externalPlatform.configurations.map((config: any, index: any) => (
                        <div className="form-group" key={index}>
                          <label>{config.name.toLocaleUpperCase()}</label>
                          <input
                            name={config.name}
                            type="text"
                            onChange={handleInputChange}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>}
          {(ischanged && organization.organizationId) && (
            <button type="submit">Save</button>
          )}
        </form>
      </div>
      {/* TODO Move this to different component */}
      <footer>
        <p>&copy; 2023 Limitless Connect - EX Connector</p>
      </footer>
    </>
  );
};

export default OrganizationSetup;
