/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { ReactElement, FC } from 'react';

const PrivacyPolicy: FC<any> = (): ReactElement => {
  return (
    <div>
      Privacy policy is under construction.
    </div>
  );
};

export default PrivacyPolicy;
