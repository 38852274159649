import axios from 'axios';

const ORGANIZATION_URL = `${process.env.REACT_APP_API
}/api/organization`;

export const useGetAllOrganizations = () => {
  const getAllOrganizations = async () => {
    return axios.get(`${ORGANIZATION_URL}`);
  };
  return { getAllOrganizations };
};


export const useGetOrganizations = () => {
  // TODO Fix any
  const getOrganizations = async (params: any) => {
    return axios.get(ORGANIZATION_URL, params);
  };
  return { getOrganizations };
};


export const useGetOrganization = () => {
  const getOrganization =  async (organizationId: number) => {
    return axios.get(`${ORGANIZATION_URL}/${organizationId}`);
  };
  return { getOrganization };
};

export const useGetOrganizationByGenesysCloudOrgId = () => {
  const getOrganizationByGenesysCloudOrgId = async (genesysOrganizationId: string) => {
    return axios.get(`${ORGANIZATION_URL}/genesysOrganizationId/${genesysOrganizationId}`);
  };
  return { getOrganizationByGenesysCloudOrgId };
};


export const useGetOrganizationConfigsByGenesysCloudOrgId = () => {
  const getOrganizationConfigsByGenesysCloudOrgId = async (genesysOrganizationId: string) => {
    return axios.get(`${ORGANIZATION_URL}/configs?genesysOrganizationId=${genesysOrganizationId}`);
  };
  return { getOrganizationConfigsByGenesysCloudOrgId };
};

export const useGetOrganizationExternalPlatforms = () => {
  const getOrganizationExternalPlatforms = async (organizationid: number) => {
    return axios.get(
      `${ORGANIZATION_URL}/${organizationid}/external-platforms`,
    );
  };
  return { getOrganizationExternalPlatforms };
};

export const useGetOrganizationExternalPlatformsByGenesysCloudOrgId = () => {
  const getOrganizationExternalPlatformsByGenesysCloudOrgId = async (genesysOrganizationId: string) => {
    return axios.get(
      `${ORGANIZATION_URL}/genesysOrganizationId/${genesysOrganizationId}/external-platforms`,
    );
  };
  return { getOrganizationExternalPlatformsByGenesysCloudOrgId };
};

export const useSaveOrganizationConfig = () => {
  const saveOrganizationConfig = async (
    organizationId: number,
    organizationConfig: any,
  ) => {
    return axios.post(`${ORGANIZATION_URL}/configs?organizationId=${organizationId}`, { organizationConfig });
  };
  return { saveOrganizationConfig };
};

// todo to update the endpoint later
export const useSyncResourcesOrganizationExternalPlatform = () => {
  const syncResourcesOrganizationExternalPlatform = async (organizationid: string, externalPlatformId: string) => {
    return axios.post(
      `${process.env.REACT_APP_API
      }/api/resource-synchronization/${organizationid}/external-platform/${externalPlatformId}`,
    );
  };
  return { syncResourcesOrganizationExternalPlatform };
};

export const useCreateInitialOrganizationConfig = () => {
  // TODO Fix any
  const createInitialOrganizationConfig = async (organization: any) => {
    return axios.post(`${ORGANIZATION_URL}/init-configs`, organization);
  };
  return { createInitialOrganizationConfig };
};

export const useCreateInitialOrganizationExternalPlatformConfig = () => {
  const createInitialOrganizationExternalPlatformConfig = async (organizationId: number, externalPlatformName: string) => {
    return axios.post(`${ORGANIZATION_URL}/${organizationId}/external-platforms/${externalPlatformName}/init-configs`);
  };
  return { createInitialOrganizationExternalPlatformConfig };
};