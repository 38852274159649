/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { ReactElement, FC, useEffect, useState } from 'react';
import {
  useGetAllLogs, useGetLogFeatures,
} from '../../services/log.service';

const AdminLogs: FC<any> = (): ReactElement => {
  const [logs, setLogs] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [selectedFeature, setSelectedFeature] = useState<any>('');
  const [selectedType, setSelectedType] = useState<any>('INFO');
  const { getAllLogs } = useGetAllLogs();
  const { getLogFeatures } = useGetLogFeatures();

  const loadLogs = async (feature: string, type: string) => {
    const fetchedData = await getAllLogs(feature, type);
    setLogs(fetchedData.data);
  };

  const loadFeatures = async () => {
    const fetchedData = await getLogFeatures();
    setFeatures(fetchedData.data);
  }


  useEffect(() => {
    loadFeatures();

    // const interval = setInterval(() => {
    //   loadLogs(selectedFeature, selectedType);
    // }, 2000);

    // // Don't forget to clear the interval when the component is unmounted
    // return () => clearInterval(interval);
  }, []);

  return (
    <div>
      Logs
      Count: {logs.length}
      <button onClick={(event) => loadLogs(selectedFeature, selectedType)}>Refresh</button>
      <br />
      {/* TODO Fix any */}
      <select onChange={(e: any) => {
        setSelectedFeature(e.target.value);
        loadLogs(e.target.value, selectedType);
      }}>
        <option value={''}> ANY </option>
        {features.map((feature: string) => (
          <option key={feature} value={feature}>
            {feature}
          </option>
        ))}
      </select>
      <select onChange={(e: any) => {
        setSelectedType(e.target.value);
        loadLogs(selectedFeature, e.target.value);
      }}>
        <option value={''}> ANY </option>
        <option value={'INFO'}> Info </option>
        <option value={'ERROR'}> Error </option>
      </select>
      <table border={1}>
        <thead>
          <tr>
            <th>log</th>
            <th>type</th>
            <th>feature</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((item: any, index: any) => (
            <tr key={index}>
              <td>{item.data}</td>
              <td>{item.type}</td>
              <td>{item.feature}</td>
              <td>{`${new Date(item.createdAt.toString())}`}</td>
              <td><a>view</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminLogs;
