import axios from 'axios';

const LOG_URL = `${process.env.REACT_APP_API
}/api/log`;

export const useGetAllLogs = () => {
  // TODO Add type
  const getAllLogs = async (feature: string, type: string) => {
    return axios.get(`${LOG_URL}`, { params: { feature, type } });
  };
  return { getAllLogs };
};

export const useGetLogFeatures = () => {
  const getLogFeatures = async () => {
    return axios.get(`${LOG_URL}/features`);
  };
  return { getLogFeatures };
};
